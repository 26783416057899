<template>
  <ion-slides pager="true" style="height: 100vh" ref="slides">
    <ion-slide style="align-items: flex-start" class="ion-padding">
      <div class="ion-slider-container">
        <h2>Vyberte profilovou fotku</h2>
        <br />
        <ion-grid
          ><ion-row>
            <ion-col
              size="6"
              size-md="6"
              size-lg="4"
              size-xl="2"
              v-for="item in avatars"
              :key="item.id"
              @click="selectAvatar(item.id)"
            >
              <ion-avatar style="width: 100%; height: 120px"
                ><img
                  :src="item.url"
                  :class="{ selected: selectedAvatar == item.id }"
              /></ion-avatar>
            </ion-col> </ion-row
        ></ion-grid>
        <div :class="{ hidden: !avatarSelected }">
          <p>
            Přejetím prstu vlevo nebo klepnutím na tlačítko "Pokračovat" se
            dostaneš na k výběru jména.
          </p>
          <ion-button
            class="btn"
            color="primary"
            expand="block"
            @click="goNext()"
            >Pokračovat</ion-button
          >
        </div>
      </div>
    </ion-slide>
    <ion-slide class="ion-padding">
      <div class="ion-slider-container">
        <h2>Zadej svoje jméno</h2>
        <p>Jméno se bude zobrazovat u příspěvků.</p>
        <ion-grid
          ><ion-row>
            <ion-col size="12">
              <div class="gray-input">
                <ion-label position="stacked">Jméno</ion-label>
                <ion-input v-model="name" type="text"></ion-input>
              </div>
              <ion-button
                color="primary"
                :disabled="!canSubmit"
                expand="block"
                @click="logIn()"
                >Přihlásit se</ion-button
              >
              <br />
              <ion-button
                class="btn"
                color="primary"
                fill="outline"
                expand="block"
                @click="goBack()"
                >Zpět</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-slide>
  </ion-slides>
</template>

<script>
import {
  IonSlides,
  IonSlide,
  IonGrid,
  IonAvatar,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonLabel,
} from "@ionic/vue";
import { mapActions } from "vuex";
export default {
  components: {
    IonSlides,
    IonSlide,
    IonGrid,
    IonAvatar,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonLabel,
  },
  data() {
    return {
      avatars: [],
      selectedAvatar: null,
      avatarSelected: false,
      nameEntered: false,
      canSubmit: false,
      name: "",
    };
  },
  watch: {
    name(value) {
      this.name = value;
      if (this.name.length > 0) {
        this.nameEntered = true;
      } else {
        this.nameEntered = false;
      }
      if (this.nameEntered && this.avatarSelected) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
  },
  beforeMount() {
    let self = this;
    this.downloadAvatars().then((res) => {
      console.log("avatarts", res);
      self.avatars = res;
    });
  },
  mounted() {},
  methods: {
    ...mapActions({
      downloadAvatars: "auth/downloadAvatars",
      updateUserInfo: "auth/updateUserInfo",
    }),
    selectAvatar(id) {
      this.selectedAvatar = id;
      this.avatarSelected = true;
    },
    logIn() {
      let self = this;
      this.updateUserInfo({
        email: "",
        name: self.name,
        avatar: self.selectedAvatar,
      }).then((res) => {
        self.$router.replace("/tabs/quizlist");
        self.$refs.slides.$el.remove();
      });
    },
    goNext() {
      // debugger;
      this.$refs.slides.$el.slideTo(1);
    },
    goBack() {
      this.$refs.slides.$el.slideTo(0);
    },
  },
};
</script>

<style scoped>
.swiper-slide {
  font-size: initial;
}
.btn {
  margin-top: auto;
}
.selected {
  border: 3px solid var(--ion-color-primary);
}
.gray-input {
  background: var(--ion-color-step-150);
  border-radius: 10px;
  padding: 12px 16px;
  margin-bottom: 16px;
  text-align: left;
}
h2,
p {
  text-align: left;
}
p {
  transition: opacity ease-in 1s;
}
.hidden {
  opacity: 0;
}
ion-input {
  --padding-bottom: 0px;
  --padding-top: 0px;
}
.ion-slider-container {
  height: 100%;
  width: 100%;
}
</style>
